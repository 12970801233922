.result-container {
	width: 100%;
	height: 100%;
	min-height: 80vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	svg {
		width: 10%;
		min-width: 120px;
		height: auto;
	}
	.result-inner {
		width: 360px;
		margin: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@media screen and (max-width: 450px) {
			width: 100%;
		}
	}
}