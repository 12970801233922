@import "../../../styles/_variables.scss";

.footer-wrapper {
	position: absolute;
	left: 0;
	right: 0;
	background-color: $primary;
	color: $white;
	display: flex;
	justify-content: center;
	// z-index: 99;
}

.footer-outer {
	max-width: $maxWidth;
	margin-left: 3rem;
	margin-right: 3rem;
	width: 100%;
	padding-top: 64px;
	padding-bottom: 64px;

	@media only screen and (max-width: $desktop) {
		margin-left: 1rem;
		margin-right: 1rem;
	}

	@media only screen and (max-width: $mobile) {
		padding-top: 32px;
		padding-bottom: 32px;
	}
}

.footer-inner {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;

	a {
		color: $white !important;
		margin-top: 0.66rem;
		margin-bottom: 0.66rem;
		&:last-child {
			margin-bottom: 0;
		}
	}

	.about {
		align-items: flex-end;

		&__logo {
			max-width: 160px;
			height: auto;
			margin-bottom: 2rem;
		}

		a {
			line-height: 0;
		}

		&__mailto {
			margin-right: 1rem;
		}

		&__social {
			margin: 0;
			line-height: 0;
		}

		p {
			margin-top: 0.25rem;
			text-align: right;
			color: $white;
		}
		
		.kennitala {
			margin-top: 0.5rem;
		}
	}

	.social {
		.facebook-logo {
			margin-right: .5rem;
		}
	}

	@media only screen and (max-width: $mobile) {
		flex-direction: column;
		align-items: flex-start;

		.about {
			align-items: flex-start;
			width: 100%;
			img {
				margin-top: 1rem;
				margin-bottom: 1rem;
			}
			&__mailto {
				margin-left: 1rem;
			}
		}

		.social {
			width: 100%;
			justify-content: flex-end;
			flex-direction: row-reverse;
			a {
				margin-right: 0;
				// margin-left: 1rem;
			}
			.facebook-logo {
				margin-right: 0;
				margin-left: .5rem;
			}
			.about {
				&__mailto {
					margin-right: 0;
					text-align: center;
					left: 0;
					right: 0;
					position: absolute;
				}
			}
		}

		.links {
			margin-bottom: 2rem;
		}

		p {
			text-align: left;
		}
	}
}