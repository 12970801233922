@import "../../../styles/_variables.scss";

.checkbox-wrapper {
	margin: 0 !important;
	label {
		margin-left: .75rem;
	}

	.checkbox {
		position: absolute; // take it out of document flow
		opacity: 0; // hide it
		height: 16px;
		width: 16px;
	
		& + label {
			position: relative;
			cursor: pointer;
			padding: 0;
		}
	
		// Box.
		& + label:before {
			content: '';
			margin-right: 10px;
			display: inline-block;
			vertical-align: text-top;
			width: 16px;
			height: 16px;
			border: 1px solid $borderColor;
			background: white;
		}
		
		// Box focus
		&:focus + label:before {
			box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
		}
		
		// Disabled state label.
		&:disabled + label {
			color: #b8b8b8;
			cursor: auto;
		}
	
		// Disabled box.
		&:disabled + label:before {
			box-shadow: none;
			background: #ddd;
		}
	
		// Checkmark. Could be replaced with an image
		&:checked + label:after {
			content: '';
			position: absolute;
			left: 4px;
			top: 8px;
			background: $primary;
			width: 2px;
			height: 2px;
			box-shadow: 
				2px 0 0 $primary,
				4px 0 0 $primary,
				4px -2px 0 $primary,
				4px -4px 0 $primary,
				4px -6px 0 $primary,
				4px -8px 0 $primary;
			transform: rotate(45deg);
		}
	}

	.checkbox-error {
		& + label:before {
			border: 1px solid $error;
		}
	}
}