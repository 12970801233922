@import "../../../styles/_variables.scss";

.permit-action-wrapper {
	@media only screen and (max-width: $tablet) {
		margin-left: -3rem;
		margin-right: -3rem;
		.permit-action-inner {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
}

.permit-header-description-container {
	flex: 3;
}

.permit-header-price-container {
	flex: 2;
}

.permit-header-quantity-container {
	flex: 1;
}
