@import "../../../styles/_variables.scss";

.header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	margin: 20px 0px;
	height: 48px;
	overflow: visible;

	@media only screen and (min-width: $desktop) {
		margin: 40px 0px;
	}

	&__logo {
		max-width: 140px;
		height: auto;
		margin-bottom: 2rem;
	}
}