@import "../../../styles/_variables.scss";

.overlay {
	z-index: 1999;
	position: fixed;
	inset: 0px;
	overflow-y: auto;
	background: rgba(34, 34, 34, 0.2);
}

.modal-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 2000;
	position: fixed;
	inset: 0px;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	@media only screen and (max-width: $modalMaxWidth) {
		min-height: 100vh;
		width: 100%;
	}
	@media only screen and (max-height: $modalMaxHeight) and (min-width: $modalMaxWidth + 1px) {
		min-height: 100vh;
		width: 100%;
	}
}

.modal {
	background: $background;
	position: relative;
	display: flex;
	flex-direction: column;
	box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px;
	border-radius: $borderRadius;
	width: 100%;
	max-width: $modalMaxWidth;
	padding: 2rem 1rem;
	@media only screen and (max-width: $modalMaxWidth) {
		height: 100%;
		overflow-y: scroll;
		.modal-inner {
			padding-bottom: 98px;
		}
	}
	@media only screen and (max-height: $modalMaxHeight) and (min-width: $modalMaxWidth + 1px) {
		height: 100%;
		min-height: 100vh;
		width: 100vw !important;
		max-width: 100vw !important;
		overflow-y: scroll;
		.modal-inner {
			width: $modalMaxWidth;
			margin: 0 auto;
			padding-bottom: 64px;
		}
	}
}

.modal-header {
	text-align: center;
	margin-bottom: 2rem;
	position: relative;
	&__button {
		position: absolute;
		left: 0px;
		top: 0px;
		color: $muted;
		button {
			height: 24px !important;
			width: 24px !important;
		}
	}
}