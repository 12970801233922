.empty-result-container {
	width: 100%;
	min-height: 100%;
	min-height: calc(100vh - 48px - 64px);
	position: relative;
	
	.empty-result-content {
		min-height: calc(100vh - 48px - 64px);
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		max-width: 200px;
		margin: 0 auto;

		.svg-container {
			margin-top: 4rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		svg {
			width: auto;
			height: 30vh;
		}

		.button-container {
			margin-top: max(8rem, 25%);
			width: 150%;
		}
	}

	.top-left {
		position: absolute;
		top: 1rem;
	}
}