@import "../../../styles/_variables.scss";

.permit-info {
	.permit-view-description-container {
        flex: 2;
	}
	
	.permit-view-price-container {
        flex: 1;
	}
	
	.permit-view-quantity-container {
        flex: 1;
	}
	
	.permit-view-total-price-container {
        flex: 1;
	}

    @media only screen and (min-width: $tablet) {
        width: 100%;
        .permit-view-description-container {
            font-size: 16px;
        }
        
        .permit-view-price-container {
            font-size: 16px;
        }
        
        .permit-view-quantity-container {
            font-size: 16px;
            font-weight: 600;
        }
        
        .permit-view-total-price-container {
            font-size: 16px;
        }
    }
}
