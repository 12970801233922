@import "../../../../../styles/_variables.scss";

.checkout-button-container {
	@media only screen and (min-width: $desktop) {
		display: block;
		position: relative;
		width: 100%;
	}
	@media only screen and (max-width: $desktop) {
		z-index: 999;
		display: block;
		position: fixed;
		bottom: 20px;
		width: 85%;
		text-align: center;
		left: 50%;
		transform: translate(-50%, 0);
	}
}

.white {
	color: $white !important;
}

.clear-cart-button {
	button {
		padding-top: 0;
		padding-bottom: 0;
	}
}