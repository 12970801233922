@import "../../../styles/_variables.scss";

@media only screen and (max-width: $maxWidth) {
	.areas-container {
		justify-content: space-between;
		position: relative;
		// min-height: calc(100vh - 48px - 369px);
	}
}


.area-date-picker {
	.DateRangePicker_picker {
		@media only screen and (min-width: 850px) {
			position: fixed !important;
			top: 140px !important;
			left: 16px !important;
		}
	}
}

.map-wrapper {
	min-height: 100vh;
	position: relative;
	padding-top: 64px;

	.header-wrapper {
		padding-top: 20px;
		position: fixed;
		z-index: 3;
		background-color: #fff;
		top: 0;
		width: 100%;

		.header {
			margin-top: 0px !important;
			margin-bottom: 0px !important;
		}
	}
	@media only screen and (max-width: $tablet) {
		padding-top: 0px;
		.header-wrapper {
			// position: unset;
		}
	}
	.map-container {
		.main-content {
			height: unset;
			min-height: unset;
		}
	}
	.footer-wrapper {
		bottom: 0;
		position: relative;
	}
}
.map-button {
	display: none;
	@media only screen and (max-width: $laptop) {
		display: block;
		position: fixed;
		bottom: 20px;
		left: 50%;
		z-index: 6;
		transform: translate(-50%, 0);
		button {
			display: flex;
			align-items: center;
		}
		svg {
			margin-right: 0.75rem;
		}
	}
}
