@import "../../../styles/_variables.scss";

.permit-info {
	svg {
		color: $muted;
		cursor: pointer;
		&:hover {
			color: $primary;
		}
	}

	.quantity {
		text-align: center;
		width: 48px;
	}

	.permit-description-container {
		flex: 3;
	}

	.permit-price-container {
		flex: 2;
		display: flex;
		justify-content: flex-start;
	}

	.permit-quantity-container {
		flex: 1;
	}
}
