@import "./_variables.scss";

html, body {
	height: 100%;
}

#root {
	overflow-x: hidden;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.row {
	display: flex;
	flex-direction: row;
}

.row-wrap {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.column {
	display: flex;
	flex-direction: column;
}

.jc-sb {
	justify-content: space-between;
}

.jc-fe {
	justify-content: flex-end;
}

.jc-c {
	justify-content: center;
}

.ai-fs {
	align-items: flex-start;
}

.ai-fe {
	align-items: flex-end;
}

.ai-c {
	align-items: center;
}

.ta-left {
	text-align: left;
}

.ta-center {
	text-align: center;
}

.ta-right {
	text-align: right;
}

.h-100 {
	height: 100vh;
}

.f-height {
	height: 100%;
	min-height: 100%;
}

.f-width {
	width: 100%;
}

.f-1 {
	flex: 1;
}

.f-15 {
	flex: 1.5;
}

.f-2 {
	flex: 2;
}

.mb-05 {
	margin-bottom: .5rem;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-2 {
	margin-bottom: 2rem;
}

.mb-3 {
	margin-bottom: 3rem;
}

.mb-8 {
	margin-bottom: 8rem;
}

.mt-1 {
	margin-top: 1rem;
}

.mt-2 {
	margin-top: 2rem;
}

.mt-3 {
	margin-top: 3rem;
}

.mv-05 {
	margin-top: .5rem;
	margin-bottom: .5rem;
}

.mv-1 {
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.mv-2 {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.mv-3 {
	margin-top: 3rem;
	margin-bottom: 3rem;
}

.mh-05 {
	margin-left: .5rem;
	margin-right: .5rem;
}

.mh-1 {
	margin-left: 1rem;
	margin-right: 1rem;
}

.mh-2 {
	margin-left: 2rem;
	margin-right: 2rem;
}

.mh-3 {
	margin-left: 3rem;
	margin-right: 3rem;
}

.ml-05 {
	margin-left: 0.5rem;
}

.ml-1 {
	margin-left: 1rem;
}

.mr-05 {
	margin-right: 0.5rem;
}

.mr-1 {
	margin-right: 1rem;
}

.pv-1 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.pv-2 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.pv-3 {
	padding-top: 3rem;
	padding-bottom: 3rem;
}

.ph-05 {
	padding-left: .5rem;
	padding-right: .5rem;
}

.ph-1 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.ph-2 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.ph-3 {
	padding-left: 3rem;
	padding-right: 3rem;
}

.bg-primary {
	background-color: $primary !important;
	&:active {
		background-color: $primaryActive !important;
	}
}

.bg-background {
	background-color: $background !important;
	&:active {
		background-color: $backgroundActive !important;
	}
}

.bg-error {
	background-color: $error !important;
	&:active {
		background-color: $errorActive !important;
	}
}

.bg-success {
	background-color: $success !important;
	&:active {
		background-color: $successActive !important;
	}
}

.bg-warning {
	background-color: $warning !important;
	&:active {
		background-color: $warningActive !important;
	}
}

.bg-text {
	background-color: $text !important;
}

.bg-muted {
	background-color: $muted !important;
}
.bg-extra-muted {
	background-color: $muted !important;
}
.bg-borderColor {
	background-color: $borderColor !important;
}

.line {
	width: 100%;
	height: .5px;
	// background-color: $muted;
	background-color: rgb(0, 0, 0, .25)
}
