@import "../../../../../styles/_variables.scss";

.area-permit-info-wrapper {
	@media only screen and (max-width: $desktop) {
		display: none;
	}
	.area-img {
		border-radius: $borderRadius;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		margin-bottom: 1rem;
		height: 350px;
	}
}