@import "../../../styles/_variables.scss";


.permit-presale {
	margin-top:16px;
}

.permit-page-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.permit-page-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		
	}

	.no-result-wrapper {

		padding-top: 50px;
		h3 {
			margin-bottom: 100px;
		}
	}

	@media only screen and (min-width: $desktop) {
		.left {
			margin-right: 2rem;
		}

		.right {
			margin-left: 2rem;
		}
	}
	
	@media only screen and (max-width: $desktop) {
		flex-direction: column;
		.permit-page-header {
			flex-direction: column;
		}
	}
}

.mobile-checkout-button {
	display: none;
	@media only screen and (max-width: $desktop) {
		display: block;
	}
}

.area-info {
	width: 33%;
	@media only screen and (max-width: $desktop) {
		display: none;
	}
}