@import "../../../styles/_variables.scss";

.permit-view-header-description-container {
	flex: 2;
}

.permit-view-header-price-container {
	flex: 1;
}

.permit-view-header-quantity-container {
	flex: 1;
}

.permit-view-header-total-price-container {
	flex: 1;
}

.permit-view-header-container {
	margin-left: 0;
	margin-right: 0;
	@media only screen and (min-width: $tablet) {
		margin-left: 1rem;
		margin-right: 1rem;
	}
}
