@import "../../../styles/_variables.scss";

.header-back-title {
	button {
		margin-right: .75rem;
	}
	svg {
		color: $primary;
		&:hover {
			color: $blue600;
		}
	}
	h3 {
		margin-block-end: 0 !important;
	}
}
