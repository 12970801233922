@import "./_variables.scss";

/** Classes for the displayed toast **/
.Toastify__toast-container--top-right {
	top: 5.5rem;
	
	@media only screen and (max-width: 480px) {
		right: 1rem;
		left: 1rem;
		width: calc(100% - 2rem);
	}
}

.Toastify__toast {
	border: 2px solid;
	min-height: 150px;
	padding: 1.5rem 1.25rem;
	background-color: $white;
	width: 100%;
	border-radius: 8px;
	margin-bottom: 1rem;
}
.Toastify__toast--info {
	border-color: $primary;
}
.Toastify__toast--success {
	border-color: $success;
}
.Toastify__toast--warning {
	border-color: $warning;
}
.Toastify__toast--error {
	border-color: $error;
}
.Toastify__toast-body {
	width: 100%;
	margin: 0;
	padding: 0;
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
	display: none;
}
