@import "../../../styles/_variables.scss";
.about-container {
  width: 70%;
  @media only screen and (max-width: $tablet) {
    width: 100%;
  }
  @media only screen and (min-width: $tablet) {
    h3 {
      font-size: 34px;
    }
    p {
      font-size: 18px;
    }
  }

  h3 {
    margin-bottom: 1rem;
  }
  .section-sell {
    margin-top: 150px;
  }
}
