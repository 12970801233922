// Sizes
$maxWidth: 1600px;
$desktop: 1300px;
$laptop: 1024px;
$tablet: 850px;
$mobile: 500px;
$borderRadius: 8px;
$padding: 20px;

// Modal
$modalMaxWidth: 500px;
$modalMaxHeight: 900px;

// Colors
$yellow400: #fdbc54;
$yellow800: #ffaa5a;
$red400: #ea9b72;
$red800: #ef7b62;
$green400: #a7b55c;
$green800: #8f9d45;
$blue200: #c6d1f5;
$blue400: #b4c7e7;
$blue600: #455e8d;
$blue800: #3d4d6c;
$grey: #707070;
$lightGrey: #DEDEDE;
$black: #3e3e3e;
$white: #ffffff;

$primary: $blue800;
$primaryActive: $blue400;
$background: $white;
$backgroundActive: $white; 
$error: $red800;
$errorActive: $red400;
$success: $green800;
$successActive: $green400;
$warning: $yellow800;
$warningActive: $yellow400;
$text: $black;
$muted: $grey;
$extraMuted: $lightGrey;
$borderColor: #a0a0a0;