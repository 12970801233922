.create-log-wrapper {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: flex-start;
	width: 100%;
	.input-wrapper,
	.select-wrapper,
	.date-picker-wrapper {
		margin-right: 8px;
		max-width: 24%;
		@media screen and (max-width: 1024px) {
			margin-right: 4px;
			max-width: 49%;
		}
		@media screen and (max-width: 768px) {
			margin-right: 0;
			max-width: 100%;
		}
	}
	.register-fish-button-wrapper {
		width: 100%;
		display: flex;
		.register-fish-button {
			max-width: 250px;
			margin: 2rem 0 2rem auto;
			@media screen and (max-width: 768px) {
				max-width: 100%;
			}
		}
	}
}