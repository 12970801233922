@import "../../../../../styles/_variables.scss";

.mobile-img {
	display: none;
	position: relative;
	@media only screen and (max-width: $tablet) {
		display: block;
	}
}

.base-img {
	border-radius: $borderRadius;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.img {
	grid-area: main;
	height: 460px;
	@media only screen and (max-width: $tablet) {
		height: 330px;
	}
	@media only screen and (max-width: 650px) {
		height: 220px;
	}
}


.area-img-grid {
	display: grid;
	position: relative;
	gap: 16px 16px;
	@media only screen and (max-width: $tablet) {
		display: none;
	}
}

.area-img-grid-4 {
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;

  grid-template-areas:
    "main main img1 img2"
    "main main img3 img4";
	
	@media only screen and (max-width: $laptop) {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		
		grid-template-areas:
			"main main img1 img1"
			"main main img2 img2";
	}
}

.area-img-grid-2 {
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;

  grid-template-areas:
    "main main img1 img2"
    "main main img1 img2";

	@media only screen and (max-width: $laptop) {
		grid-template-areas:
		"main main img1 img1"
		"main main img2 img2";
	}

	.img3 {
		display: none;
	}
	.img4 {
		display: none;
	}
}

.img1 { grid-area: img1 };
.img2 { grid-area: img2 };

.img3 {
	grid-area: img3;
	@media only screen and (max-width: $laptop) {
		display: none;
	}
};

.img4 {
	grid-area: img4;
	@media only screen and (max-width: $laptop) {
		display: none;
	}
};

.sm-img {
	@media only screen and (max-width: $tablet) {
		display: none;
	}
}

.images-button {
	position: absolute;
	right: 12px;
	bottom: 12px;
}