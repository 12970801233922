@import '../../../../../styles/_variables.scss';
.area-info-wrapper {
  position: relative;
  bottom: 2px;
  left: 3px;
  background-color: white;
  padding: 8px;
  border: 1px solid $borderColor;
  border-radius: 8px;
  width: 224px;
  z-index: 100;
  .img {
      width: 207px;
      height: 137px;
      border-radius: 8px;
  }
	
	.price-info {
        font-size: 16px;
        text-align: right;
    }
}

.area-card {
	position: relative;
  bottom: 2px !important;
  left: 18px;
	width: 300px;
	z-index: 100;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}