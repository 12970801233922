@import "../../../styles/_variables.scss";

.button-container {
  display: inline-block;
  button {
    cursor: pointer;
    text-align: center;
    height: 48px;
    padding: 0.75rem 1rem;
    width: 100%;
    align-items: center;
    justify-items: center;
    background-color: $primary;
    color: $white;
    border-radius: $borderRadius;
    &.active {
      color: $white;
      background-color: $primary;
    }

    .bold {
      font-weight: 600;
    }

    &:active {
      background-color: $blue600;
      transition: all .1s linear;
      transform: scale(.98);
    }
  }

  .inverse {
    background-color: $white !important;
    color: $primary !important;
    border: 1px solid $borderColor;
  }
}

.button-smaller {
  button {
    height: 36px;
    padding: .25rem .5rem;
  }
}

.button-block {
  width: 100%;
}