
@import "../../../styles/_variables.scss";
.single-booking {
    padding-top: 53px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: $laptop) {
        flex-direction: column;
        padding-top: 16px;
    }

    .permit-wrapper {
        width: calc(50% - 50px);
        @media only screen and (max-width: $laptop) {
            width: 100%;
        }
    }

    .booking-body {
        width: calc(50% - 50px);
        @media only screen and (max-width: $laptop) {
            width: 100%;
        }
        .table-header {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            .header-item {
                font-size: 18px;
                font-weight: 600;
                color: $primary;
            }
        }

        .table-body {
            padding-top: 22px;
            .table-item {
                
                .table-item-header {

                }
                .table-item-body {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    justify-content: space-between;
                }
            }
        }
    }
    .booking-actions {
        width: calc(50% - 50px);
        @media only screen and (max-width: $laptop) {
            margin-top: 50px;
            width: 100%;
        }
        .booking-info {
            .info-item {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}