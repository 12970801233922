@import "../../../styles/_variables.scss";

.presale {
	background: $red800;
	border-radius: 16px;
	padding: 4px;
	text-align:center;
	margin-bottom:8px;
	font-weight: bold;
}


@media only screen and (min-width: $desktop + 1px) {
	// CSS for laptop and larger
	.area-page-container {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;

		.area-right {
			min-width: 33%;
			// position: relative;
			// display: flex;
			justify-content: flex-end;
			.area-right-inner {
				// position: fixed;
			}
		}

	
		.area-left {
			min-width: 63%;
			max-width: 63%;
			align-self: flex-start;

			.area-line {
				display: none;
			}
		}
	}
}

.all-permits-button {
	margin-top: 16px;
}

@media only screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
	.area-page-container {
		.area-right-inner {
			width: 100%;
			display: flex;
			flex-direction: row-reverse;
			justify-content: space-between;
		}
		.area-right {
			margin: 1.5rem 0;
		}
	}
}

@media only screen and (max-width: $tablet) {
	// CSS for smaller than laptop.
	.area-page-container {
		display: flex;
		flex-direction: column;
		.area-right {
			margin: 2rem 0;
		}
	}
}
