.logs-page-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.log-area-filters {
	display: flex;
	flex-direction: row;
	flex: 1;
	width: 100%;
	justify-content: flex-end;
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
	}
	.select-wrapper {
		width: 250px;
		margin-left: 12px;
		@media screen and (max-width: 600px) {
			min-width: 300px;
			margin-left: 0;
			width: 100%;
		}
	}
}