@import "../../../styles/_variables.scss";

.banner {
	position: fixed;
	top: 16px;
	left: -32px;
	height: 24px;
	font-size: 12px;
	width: 128px;
	transform: rotate(-45deg);
	background-color: $warning;
	color: $white;
	text-align: center;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 999999;
}