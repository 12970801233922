@import "../../../styles/_variables.scss";

.no-label {
	padding-top: 12px;
}

.input-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	position: relative;
	margin: 0.5rem 0rem;

	label {
		font-size: 14px;
		color: $grey;
		font-weight: 500;
	}

	input, textarea {
		margin-top: 12px;
		margin-bottom: 16px;
		border-radius: $borderRadius;
		font-size: 16px;

		&:focus {
			box-shadow: 0 0 0 1pt $success;
			border-color: $success;
		}
	}

	textarea {
		min-height: 128px;
		padding: 8px;
	}

	.error {
		position: absolute;
		display: flex;
		flex-direction: row;
		align-items: center;
		top: 2px;
		right: 0;
		height: 12px;
		font-size: 14px;
		color: $warning;
		line-height: 0;
		svg {
			margin-bottom: auto;
			margin-left: 4px;
			width: 14px;
			height: 14px;
		}
	}

	.field {
		height: 48px;
		width: 100%;
		background-color: $white;
		border: 1px solid $borderColor;
		padding-left: 0.75rem;
	}

	.field-error {
		box-shadow: 0 0 0 1pt $warning !important;
		border-color: $warning;
		&:focus {
			box-shadow: 0 0 0 1pt $warning !important;
			border-color: $warning;
		}
	}
}