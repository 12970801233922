@import "../../../../styles/_variables.scss";

.table-container {
  /* This is required to make the table full-width */
  display: block;
  margin-left: -20px;
  margin-right: -20px;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    padding-right: 20px;
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;

    @media screen and (max-width: 1024px) {
      min-width: 100vw;
    }

    @media screen and (min-width: 1024px) {
      padding-left: 20px;
    }
  }

  table {
    border: none;
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    thead {
      th {
        background-color: #FFF !important;
        text-align: left;
      }
      color: #3D4D6C !important;
    }

    th,
    td {
      white-space: nowrap;
      border: 0;
      margin: 0;
      padding: 0.5rem;
      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }

    tr:nth-child(odd) {
    	background-color: rgba(198, 209, 245, .35);
    }
    tr:nth-child(even) {background: #FFF}
  }

  .pagination {
    padding: 0.5rem;
  }
}