@import './_variables.scss';

// Fonts
$headerSize: 1rem;
$textSize: 14px;

@font-face {
  font-family: 'AirbnbCereal';
  src: url('/assets/fonts/AirbnbCerealBlack.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'AirbnbCereal';
  src: url('/assets/fonts/AirbnbCerealExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'AirbnbCereal';
  src: url('/assets/fonts/AirbnbCerealBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'AirbnbCereal';
  src: url('/assets/fonts/AirbnbCerealMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AirbnbCereal';
  src: url('/assets/fonts/AirbnbCerealBook.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'AirbnbCereal';
  src: url('/assets/fonts/AirbnbCerealLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
button {
  font-family: 'AirbnbCereal';
  font-size: $headerSize;
  color: $text;
  text-decoration: none;
}

p,
input,
label,
textarea,
ul,
a,
span,
li,
div {
  line-height: 1.5;
  font-family: 'AirbnbCereal';
  color: $text;
  text-decoration: none;
}

p {
  font-weight: 300;
}

h1 {
  line-height: 1.05;
  font-weight: 600;
  font-size: 68px;
  @media only screen and (max-width: $tablet) {
    font-size: 28px;
  }
}

h2 {
  font-weight: 500;
  font-size: 22px;
}

h3 {
  font-weight: 500;
  font-size: 20px;
}

h4 {
  font-weight: 500;
  font-size: 18px;
}

h5 {
  font-weight: 500;
  font-size: 16px;
}

h6 {
  font-weight: 500;
  font-size: 14px;
}

.black {
  font-weight: 800;
}

.extrabold {
  font-weight: 700;
}

.bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.muted {
  color: $muted;
}

.default {
  color: $black;
}

.extra-muted {
  color: $muted;
}

.book {
  font-weight: 400;
}

.light {
  font-weight: 300;
}

.hero-larger {
  font-size: 34px;
  line-height: 1.3;
  font-weight: 300;
  @media only screen and (max-width: $tablet) {
    font-size: 18px;
  }
}

.extra-larger {
  font-size: 24px;
  line-height: 0.5;
}

.larger {
  font-size: 18px;
}

.small {
  font-size: 14px;
}

.smaller {
  font-size: 12px;
}

.italic {
  font-style: italic;
}

.extra-smaller {
  font-size: 10px;
}

.inverse {
  color: $white;
}

.no-wrap {
  white-space: nowrap;
}

.black {
  color: $black !important;
}

.primary {
  color: $primary !important;
}

.success {
  color: $success !important;
}

.warning {
  color: $warning;
}

.underline {
  text-decoration: underline;
}

a {
  color: $primary;
  text-decoration: inherit;
  -webkit-transition: all 0.35s linear;
  -o-transition: all 0.35s linear;
  transition: all 0.35s linear;
}

a:hover,
.btn-link:hover {
  color: $blue600;
}

.pointer {
  cursor: pointer;
}
