@import '../../../styles/_variables.scss';

.blue-background {
  background-color: rgba(198, 209, 245, 0.35);
}

.area-link {
  font-weight: 600;
  height: 37px;
  flex: 1;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: -10px;
  margin-right: -10px;
  a {
    width: 100%;
  }
  .area-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media only screen and (max-width: $tablet) {
    padding: 1rem 0;
  }
  @media only screen and (min-width: $tablet) {
    padding: 0 !important;
  }

  .table-detail {
    text-align: right;
    width: 110px;
    @media only screen and (max-width: 600px) {
      width: 80px;
      white-space: nowrap;
      .year {
        display: none;
      }
    }
  }

  .table-name {
    width: 200px;
    @media only screen and (max-width: 600px) {
      width: 100px;
    }
  }

  svg {
    color: $primary;
  }
}
