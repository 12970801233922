@import "../../../../styles/_variables.scss";

.icon {
	position: relative;
	text-align: right;
	cursor: pointer;
	border: 1px solid #3D4D6C;
	padding: 6px 8px;
	border-radius: $borderRadius;
	width: 64px;
	margin-left: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: nowrap;

	.user-icon {
		margin-left: .5rem;
		svg {
			width: 18px;
			height: auto;
			fill: #3D4D6C;
		}
	}

	.notification {
		background-color: $warning;
		height: 8px;
		width: 8px;
		border-radius: 50%;
		position: absolute;
		bottom: 8px;
		right: 6px;
	}
}

.inverse {
	border-color: $white;	
}

.menu-container {
	z-index: 9999;
	position: relative;
	bottom: -8px;
	background-color: $white;
	border-radius: $borderRadius;
	border: 1px solid $borderColor;
	min-width: 128px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
}

.link {
	cursor: pointer;
	margin: 1rem 3rem 1rem 1rem;
	white-space: nowrap;
}