@import "../../styles/_variables.scss";

.main-wrapper {
	max-width: $maxWidth;
	min-height: 100vh;
	margin-left: 20px;
	margin-right: 20px;

	@media only screen and (min-width: $desktop) {
		margin-left: 60px;
		margin-right: 60px;
	}

	@media only screen and (min-width: $maxWidth + 60px) {
		margin-left: auto;
		margin-right: auto;
	}

	.main-container {
		height: 100%;
	}
}

.map-wrapper {
	// min-height: 100vh;
	width: 100%;
	.map-container {
		height: 100%;
	}
	.header-wrapper {
		// max-width: $maxWidth;
		padding-left: 1rem;
		padding-right: 1rem;
	
		@media only screen and (min-width: $desktop) {
			// margin-left: 60px;
			// margin-right: 60px;
		}
	
		@media only screen and (min-width: $maxWidth + 60px) {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.footer-outer {
		max-width: unset;
		padding-left: 1rem;
		padding-right: 1rem;
	
		@media only screen and (min-width: $desktop) {
			margin-left: 60px;
			margin-right: 60px;
		}
	
		@media only screen and (min-width: $maxWidth + 60px) {
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.main-content {
	min-height: calc(100vh - 48px - 64px);
}

.gradient {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	background-image: linear-gradient(to top, #FFF 0%, #455E8D 100%);
	z-index: -1;
}
