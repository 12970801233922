.area-info-points {
	p {
		display: inline;
		white-space: nowrap;
	}
	p:after {
		margin: 0 4px;
		content: "\00b7";
	}
	p:last-child:after {
		content: ""
	}
}
