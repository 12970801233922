@import "../../../styles/_variables.scss";

.select-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	position: relative;
	margin: 0.5rem 0rem;

	label {
		font-size: 14px;
		color:$grey;
	}

	.error {
		position: absolute;
		display: flex;
		flex-direction: row;
		align-items: center;
		top: 2px;
		right: 0;
		height: 12px;
		font-size: 14px;
		color: $warning;
		line-height: 0;
		svg {
			margin-bottom: auto;
			margin-left: 4px;
			width: 14px;
			height: 14px;
		}
	}
}

.select {
	&__control {
		margin-top: 12px !important;
		margin-bottom: 16px !important;
		border-radius: $borderRadius !important;
		font-size: 14px !important;
		
		border: 1px solid $borderColor !important;
		height: 48px !important;
	}

	&__value-container {
		height: 48px !important;
	}

	&__control--is-focused {
		box-shadow: 0 0 0 1pt $success !important;
		border-color: $success !important;
	}

	&__option {
		// border-bottom: 1px dashed lightsalmon;
		background: #fff;
		transition: background-color 100ms ease-in-out;
	}

	&__option--is-focused {
		background-color: $blue400 !important;
	}

	&__option--is-selected {
		background-color: $primary !important;
		color: #fff !important;
	}

	&__indicator-separator {
		display: none !important;
	}

	&__dropdown-indicator {
		color: $grey !important;
	}

	&__placeholder {
		opacity: 0.6 !important;
	}

	&__single-value {
		opacity: 1;
	}

	&__menu {
		margin-top: -8px !important;
		padding: 0;
	}

	&__menu-list {
		padding: 0 !important;
	}
}


