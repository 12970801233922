@import "../../../styles/_variables.scss";

.blue-background {
	background-color: rgba(198, 209, 245, .35);
}

.permit-date {
	span {
		font-weight: 400;
		color: $primary;
	}
}

.permit-section {
	@media only screen and (max-width: $tablet) {
		margin-left: -3rem;
		margin-right: -3rem;
		padding: 1rem 1rem 0.5rem;
		.permit-section-inner {
			padding-left: 2rem;
			padding-right: 2rem;
		}
	}
	@media only screen and (min-width: $tablet) {
		padding: 1rem 1rem 0.5rem;
        .permit-date {
            font-size: 22px;
						color: $primary;
						span {
							font-size: 18px;
						}
        }
	}
	.permit-info:last-child {
		margin-bottom: 0.5rem;
	}
}