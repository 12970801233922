.success-result-container {
	width: 100%;
	height: 100%;
	min-height: 90vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	svg {
		width: 10%;
		min-width: 100px;
		height: auto;
	}
}