@import '../../../../../styles/_variables.scss';

.marker {
  border: 1px solid $borderColor;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10 !important;
  padding: 4px;
  &:hover {
    background-color: white;
    transform: scale(1.1);
  }
};