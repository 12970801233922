@import "../../../../../styles/_variables.scss";

.no-border {
    border-bottom: none !important;
}

.profile-item {
    width: 100%;
    border-bottom: 1px solid rgb(0, 0, 0, .25);
    margin-bottom: 32px;
    &:first-of-type {
        margin-top: 40px;
    }
    .profile-item-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .change-profile-button {
            background-color: transparent;
            color: $green400;
            cursor: pointer;
            &:disabled {
                color: $extraMuted;
            }
        }
    }
    .profile-item-body {
        margin-top: 13px;
        padding-bottom: 37px;
        font-size: 18px;
    }

    .profile-item-form {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 16px;
        input {
            max-width: 350px;
        }
        .profile-item-row {
            display: flex;
            width: 100%;
            @media only screen and (max-width: $tablet) {
                flex-direction: column;
            }
            .profile-item-column {
                flex: 1;
                @media only screen and (min-width: $tablet) {
                    margin-right: 16px;
                }
            }
        }
        button {
            background-color: $green800;
            padding: 0.75rem 3rem;
            &:active {
                background-color: $green400;
                transition: all .1s linear;
                transform: scale(.98);
              }
        }
    }
}