@import "../../../../styles/_variables.scss";

.list {
	width: 40%;
	height: 100%;
	position: relative;
	overflow-y: scroll;
	padding-top: 5px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding-right: 0;
	min-height: calc(100vh - 68px);
	@media only screen and (max-width: $desktop) {
		width: 50%;
	}
	@media only screen and (max-width: $laptop) {
		width: 100%;
	}
	@media only screen and (max-width: $tablet) {
		padding-top: 80px;
	}
	@media only screen and (max-width: $mobile) {
		.range-picker-container {
			width: calc(100% - 60px);
			.DateRangePicker,
			.DateRangePickerInput {
				width: 100%;
			}
			.DateInput {
				width: 47%;
			}
			.to-label {
				left: 46%;
			}
		}
	}

	.burger-button {
		height: 61px;
		width: 61px;

		margin-left: 8px;
		@media only screen and (max-width: $tablet) {
			height: 52px;
			width: 52px;
		}
		button {
			height: 100%;
		}
	}
}

.area-pagination {
	@media only screen and (max-width: $laptop) {
		margin-bottom: 4rem;
	}
}

.info-points {
	p {
		display: inline;
		white-space: nowrap;
	}
	p:after {
		margin: 0 4px;
		content: "\00b7";
	}
	p:last-child:after {
		content: "";
	}
}

.map-container-wrapper {
	height: 100%;
	width: auto;
}
.map {
	width: 60%;
	height: calc(100vh - 68px);
	top: 68px;
	border-radius: 8px;
	position: fixed;
	right: 0;
	div {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
	}
	@media only screen and (max-width: $desktop) {
		width: 50%;
	}
	@media only screen and (max-width: $laptop) {
		display: none;
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0;
		bottom: 0;
	}
}

.map-visible {
	display: block !important;
	z-index: 4;
}

.area-list-item {
	margin: 1rem;
	display: flex;

	@media only screen and (max-width: $tablet) {
		flex-direction: column;
	}

	.item-title {
		// display: flex;
		// flex-direction: row;
		// align-items: center;

		.fish-types-wrapper {
			display: inline-block;
			height: 100%;
			transform: translateY(-2px);
		}
		.fish-types {
			display: flex;
			flex-direction: row;
		}
		.status {
			width: 12px;
			height: 12px;
			border-radius: 50%;
			margin-right: 6px;
		}
	}

	.img {
		border-radius: $borderRadius;
		height: 200px;
		min-width: 50%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		margin-bottom: 1rem;
		@media only screen and (max-width: $tablet) {
			width: auto;
			min-width: none;
			height: 212px;
		}
	}

	.info {
		margin-left: 16px;
		@media only screen and (max-width: $mobile) {
			margin-left: 0px;
		}
		.name {
			font-size: 28px;
			display: inline;
			line-height: 1.15;
		}
	}
}
