@import "../../../styles/_variables.scss";

.range-picker-container {
	display: flex;
	flex-direction: row;
	position: relative;
	.from-label {
		position: absolute;
		left: 11px;
		top: 8px;
		font-size: 16px;
		color: $primary;
		font-weight: 500;
	}
	.to-label {
		position: absolute;
		left: 141px;
		top: 8px;
		font-size: 16px;
		color: $primary;
		font-weight: 500;
	}
	@media only screen and (max-width: $tablet) {
		.from-label {
			left: 8px;
			font-size: 14px;
		}
		.to-label {
			font-size: 14px;
			left: 111px;
		}

		input {
			font-size: 14px !important;
		}
	}
	.search-button {
		height: 46px;
		margin-left: -8px;
		z-index: 1;
		border-radius: 0px 8px 8px 0px;
		background-color: $primary;
		max-height: 52px !important;

		@media only screen and (max-width: $tablet) {
			flex: 1;
			max-width: 128px;
			min-height: 32px;
			height: 52px;
		}
		button {
			border-radius: 0px 8px 8px 0px;
			min-height: 46px;
			height: 61px;
			@media only screen and (max-width: $tablet) {
				min-height: 32px;
				height: 52px;
				font-size: 14px;
			}
		}
	}
}

.DateRangePicker {
	height: 52px !important;
}

.DateRangePickerInput {
	white-space: nowrap;
	border: 1px solid $borderColor;
	border-radius: 8px;
	
	padding-top: 13px;
	overflow: hidden;
	.DateRangePickerInput_arrow_svg {
		display: none;
	}
	.DateInput_input {
		font-size: 16px;
	}
	@media only screen and (max-width: $tablet) {
		padding-top: 16px;
		padding-bottom: 2px;
	}

	.DateInput__small {
		width: 100px;
	}
}

