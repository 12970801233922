.coupon-row {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	width: 100%;
	.input-wrapper {
		width: 80%;
		margin-bottom: 0px;
	}
	input {
		margin-bottom: 0px;
		border-bottom-right-radius: 0px;
		border-top-right-radius: 0px;
	}
	button {
		height: 48px;
		border-top-left-radius: 0px;
		border-bottom-left-radius: 0px;
	}
	.button-container {
		width: 20%;
	}
}