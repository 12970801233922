@import "../../../styles/_variables.scss";

.terms-container {
	.section {
		margin-bottom: 64px;
		h3 {
			margin-bottom: 32px;
		}
		@media only screen and (max-width: $tablet) {
			margin-bottom: 32px;
			h3 {
				margin-bottom: 16px;
			}
		}
	}
	width: 70%;
	@media only screen and (max-width: $tablet) {
		width: 100%;
	}
	@media only screen and (min-width: $tablet) {
		h3 {
			font-size: 34px;
		}
		p {
			font-size: 18px;
		}
	}

	h4 {
		margin-bottom: 1rem;
	}
}