@import "../../../styles/_variables.scss";

.checkout-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	@media only screen and (min-width: $tablet) {
		.summary-line {
			display: none;
		}

		.left {
			margin-right: 2rem;
		}

		.right {
			margin-left: 2rem;
		}
	}
	
	@media only screen and (max-width: $tablet) {
		flex-direction: column;
	}
}