@import "../../../styles/_variables.scss";

.active-page {
	background-color: $primary;
	color: $white;
	border-radius: 50%;
	height: 36px;
	width: 36px;
	margin: 0 !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}