@import "../../../styles/_variables.scss";

.privacy-container {
	
	width: 70%;
	@media only screen and (max-width: $tablet) {
		width: 100%;
	}

	h4 {
		margin-bottom: 1rem;
	}
}