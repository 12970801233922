@import "../../styles/_variables.scss";

.toast-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	h2 {
		font-size: 24px;
	}

	.button__warning {
		color: $warning;
	}

	.button__error {
		color: $error;
	}

	.button__info {
		color: $primary;
	}

	.button__success {
		color: $success;
	}
}
