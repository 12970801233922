 @import "../../../styles/_variables.scss";

.front-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	min-height: 85vh;
	width: 100%;

	@media only screen and (max-width: $mobile) {
		min-height: unset;
		padding-top: 75px;
	}
}

.front-inner {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;

	.hero {
		display: flex;
		flex-direction: column;
	}
	.hero-image-container {
		width: 50%;

		.heroimg {
			width: 100%;
		}
	}

	@media only screen and (max-width: $laptop) {
		flex-direction: column;

		.hero {
			margin-bottom: 2rem;
		}
		.hero-image-container {
			margin-top: 2rem;
			width: 77%;
			min-width: 456px;
			margin: auto;
		}
	}
	@media only screen and (max-width: $tablet) {
		.heroimg {
			height: unset;
		}
	}
	@media only screen and (max-width: $mobile) {
		.front-container {
			min-height: unset;
			padding-top: 50px;
		}
		.hero-image-container {
			margin-top: 2rem;
			width: 100%;
			min-width: unset;
			margin: auto;
		}
	}
}

.all-areas-button {
	max-width: 141px;
}

.bottom-hero {
	width: 66.7%;
	margin: 125px 0;
	margin-bottom: 250px;
	@media only screen and (max-width: $tablet) {
		width: 100%;
		margin: 55px 0;
	}
}

.info-section {
	background-color: rgba(198, 209, 245, 0.35);
	margin-left: -100vw;
	margin-right: -100vw;
	padding: 3rem 0;

	.info-inner {
		padding-left: 100vw;
		padding-right: 100vw;
	}

	.about-item {
		max-width: 320px;
		flex: 1;

		

		img {
			width: 100%;
		}
		h3 {
			font-weight: 300;
		}
	}
	@media only screen and (max-width: $desktop) {
		.info-section-items {
			.about-item {
				max-width: 33%;
				flex: 1;

				img {
					width: 100%;
				}
			}
		}
	}
	@media only screen and (max-width: $laptop) {
		.info-section-items {
			.about-item {
				max-width: 33%;
				flex: 1;

				img {
					width: 100%;
				}
			}
		}
	}

	@media only screen and (max-width: $tablet) {
		.row {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}	
	}

	@media only screen and (max-width: $mobile) {
		.info-section-items {
			align-items: center;
			flex-direction: column;
			margin: 0;
			.about-item {
				max-width: unset;
				flex-direction: row-reverse;
				justify-content: space-between;
				width: 100%;

				img {
					width: 50%;
				}
				h3 {
					width: 40%;
					margin-top: 0px !important;
				}

				
			}
		}
	}
}
