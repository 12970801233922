@import "../../../styles/_variables.scss";

.icon-button-container {
  display: inline-block;
  button {
    cursor: pointer;
    text-align: center;
    height: 32px;
    width: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $white;
    color: $white;
    border-radius: 50%;
    border: 1px solid #707070;
    &.active {
      color: $white;
      background-color: $lightGrey;
    }

    &:disabled {
      border: 1px solid $borderColor;
    }

    &:active {
      background-color: $lightGrey;
      transition: all .1s linear;
      transform: scale(.98);
    }

    svg {
      height: 18px;
      width: 18px;
    }
  }
  .b-primary {
    background-color: $primary;
    border: none;
    &:active {
      background-color: $blue600;
    }
  }
}
